

$('[ data-load-popular-destinations]').click(function() {
    let type = $(this).attr('data-type');



    $.ajax({
        _token: $('meta[name="csrf-token"]').attr("content"),
        url: "/popular-destinations/load-more",
        data: {type: type},
        beforeSend: function() { 
           
        },
        success: function(data){
            $('[data-load-popular-destinations].active').removeClass( 'active' );
            $('[data-load-popular-destinations][data-type="'+type+'"]').addClass( 'active' );
            $('[data-main-popular-all]').each( function() {
                $(this).attr( 'data-type', type );
            });
            $('[data-load-popular-list]').html(data);
        },
        error: function () {
            
        },
    });

    return false;
});

$('[data-main-popular-all]').click(function(e){
    e.preventDefault();

    let current_type = $(this).attr('data-type'),
        current_href = $(this).attr('data-href-' + current_type);

    window.location.href = current_href;
});